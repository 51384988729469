import axios from 'axios'
let imgUrl = 'https://med-wiki.cos.xenpie.com'
let pdfUrl = {
  '1': 'disease',
  '2': 'medicine',
  '3': 'operation',
  '4': 'diagnostic_examination',
  '5': 'confirmation',
  '6': 'disease_research',
  '8': 'operating_discipline'
}
export default {
  data(){
    return {
      data: {},
      loading: false,
    }
  },
  methods: {
    getData(obj){
      axios({
        methods: 'get',
        url: 'http://med-wiki.xenpie.com/api/',
        params: obj
      }).then(res => {
        if (res.data.status == 200){
          let data = res.data.data.detail, shuju = {}
          Object.keys(data).forEach(item => {
            if (data[item]){
              shuju[item] = data[item].toString().replace(/<a .*?href=['"](.*?)['"].*?>(.*?)<\/a>/g, '<a href="javascript:void(0)" id="yxsjk" onclick="onLink(\'$1\')">$2</a>')
                .replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
                  let img = new Image();
                  img.src = capture;
                  let quan = img.src.replace(window.location.origin, `${imgUrl}`);
                  return match.replace(capture, quan)
                });
            }else {
              shuju[item] = ''
            }
          })
          this.data = shuju
          window.onLink = function onLink(url){
            let img = new Image();
            img.src = url;
            url = img.src.replace(window.location.origin, '');
            if (url.indexOf('Details.aspx') !== -1){
              // 包含
              window.location.href = url
            }else {
              // 传参到父页面
              window.parent.postMessage(url,'*');
            }
          }
        }
      })
    },

    onDownload(row, type){
      let url = `${imgUrl}/pdf/${pdfUrl[type]}/${row.id}.pdf`
      window.open(url)
    },
    //过滤a标签
    filterA(text){
      if (text){
        return text.replace(/<a .*?href=['"](.*?)['"].*?>(.*?)<\/a>/g, function(match, capture, value){
          return value
        })
      }else{
        return ''
      }
    }
  },
  beforeDestroy(){
    window.onLink.removeEventListener('click',function () {

    },false);
  }
}
